export default {
  // Set Filter
  selectAll: '(Selecionar todos)',
  selectAllSearchResults: '(Selecionar resultado da busca)',
  searchOoo: 'Buscando...',
  blanks: '(Em Branco)',
  noMatches: 'Nenhum resultado combina com a busca',

  // Number Filter & Text Filter
  filterOoo: 'Filtrando...',
  equals: 'Igual',
  notEqual: 'Não igual',
  empty: 'Escolha um',

  // Header of the Default Group Column
  group: 'Grupo',

  // Other
  loadingOoo: 'Carregando...',
  noRowsToShow: 'Não há dados disponíveis',
  enabled: 'Habilitado',

  // Menu
  expandAll: 'Expandir todos',
  collapseAll: 'Fechar todos',
  copy: 'Copiar',
  ctrlC: 'Ctrl+C',
  copyWithHeaders: 'Copiar com cabeçalho',
  paste: 'Colar',
  ctrlV: 'Ctrl+V',
  export: 'Exportar',
  csvExport: 'Export CSV',
  excelExport: 'Exportar Excel',

  // Enterprise Menu Aggregation and Status Bar
  sum: 'Soma',
  min: 'Mínimo',
  max: 'Máximo',
  none: 'Nenhum',
  count: 'Contagem',
  avg: 'Média',
  filteredRows: 'Filtrados',
  selectedRows: 'Selecionados',
  totalRows: 'Total linhas',
  totalAndFilteredRows: 'Linhas',
  more: 'Mais',
  to: 'a',
  of: 'de',
  page: 'Página',
  nextPage: 'Próxima página',
  lastPage: 'Última página',
  firstPage: 'Primeira página',
  previousPage: 'Página anterior',

  // Pivoting
  pivotColumnGroupTotals: 'Total',
};
